import { useCurrentUser } from 'vuefire'
import { computed } from 'vue'

export function useAwakenRole() {
  const user = useCurrentUser()

  const hasAwakenRole = computed(() => {
    if (!user.value) return false

    return user.value
      .getIdTokenResult()
      .then((tokenResult) => (tokenResult?.claims?.permits as string[] | undefined)?.includes('AWAKEN') ?? false)
  })

  return { hasAwakenRole }
}
